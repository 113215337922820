import { store } from "react-notifications-component";
import storage from "../utility/storage";
import { useEffect } from "react";
import { ACCENT_COLORS, AWS_CONSTANTS, ACCESS_LEVEL, ACCESS_LEVELS, DEFAULT_TIME_FORMAT } from "./constants/constants";
import { Storage } from "aws-amplify";
import { lastIndexOf } from "lodash";
import swal from "sweetalert";
import moment from "moment-timezone";

export const toFloatWithDecimal = (number) => {
  return parseFloat(number).toFixed(2);
};

export const manupulatingTime = (time) => {
  const manipulatedTime = {};
  const T = time.split(":");
  manipulatedTime["hour"] = T[0];
  manipulatedTime["minute"] = T[1];
  return manipulatedTime;
};
// Replace AM and PM
export const replaceAMPM = (time) => {
  if (time.includes("AM")) {
    return time.replace("AM", "");
  } else if (time.includes("PM")) {
    return time.replace("PM", "");
  } else {
    return time;
  }
};

export const formatTime = (runTime, user) => {
  const dateTimeObj = moment(runTime).tz(user.timezone);
  return dateTimeObj.format(
    user.date_format.concat(" ", DEFAULT_TIME_FORMAT)
  );
};

export const closeCompaignUI = (enable) => {
  storage.set("close_campaign_ui", enable);
};

export const isCompaignUIClosed = () => {
  return storage.get("close_campaign_ui", false);
};

export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

// Calendar logic
export const getStartTimeAndEndTime = (startTime, endTime) => {
  const startTimeArray = startTime.split(":");
  const startTimeHour = parseInt(startTimeArray[0]);
  const startTimeMinute = parseInt(startTimeArray[1]);

  const endTimeArray = endTime.split(":");
  const endTimeHour = parseInt(endTimeArray[0]);
  const endTimeMinute = parseInt(endTimeArray[1]);
  return { startTimeHour, startTimeMinute, endTimeHour, endTimeMinute };
};

export const daysInThisMonth = (currentDate) => {
  var now = currentDate;
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
};

// providers Attributes Time Logic

export const EndTimeValidation = (startTime) => {
  const arr = startTime.split(":");
  let hour = parseInt(arr[0]);
  let minute = parseInt(arr[1]);

  if (minute + 15 !== 60) {
    minute = minute + 15;
    return hour.toString().concat(":" + minute.toString());
  } else {
    hour = hour + 1;
    return hour.toString().concat(":00");
  }
};

// for production removing console logs
export const removeConsoleLog = () => {
  function emptyfunc() {}
  console.log = emptyfunc;
  console.warn = emptyfunc;
  console.error = emptyfunc;
};

export const addDateTime = (date, month, year, hours, minutes) => {
  var mont = parseInt(month) + 1;
  var x = year + "," + mont + "," + date + " " + hours + ":" + minutes;
  var dt = new Date(x);
  return dt;
};

export const addSecondsToDate = (date, seconds) => {
  var month = parseInt(date.getMonth()) + 1;
  var x =
    date.getFullYear() +
    "," +
    month +
    "," +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    seconds;
  var dt = new Date(x);

  return dt;
};

///////// Service provider listing availability section - Hours computation logic

export const getHoursBetween = (startDate, endDate) => {
  let diff = endDate.getHours() - startDate.getHours();
  var diffDates = [];
  for (var i = 0; i < diff; i++) {
    let date = new Date(startDate);
    date.setHours(startDate.getHours() + i);
    diffDates.push(date);
  }
  return diffDates;
};

export const getDateKey = (date) => {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const pad = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const getFormattedTime = (date) => {
  let hrs = pad(date.getHours(), 2);
  let minutes = date.getMinutes() === 0 ? "00" : pad(date.getMinutes(), 2);
  return hrs + ":" + minutes;
};

//////////////////////////////////////////////////////////////////////////////////////
//May 04, 2019
export const dateToString = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var mm = date.getMonth();
  var dt = date.getDate();
  var year = date.getFullYear();
  return months[mm] + " " + dt + ", " + year;
};

export const roundOff = (date) => {
  var offset = 15;
  var tempDate = new Date(date);
  var minutes = date.getMinutes();
  var newMin = minutes + (offset - (minutes % offset));
  tempDate.setMinutes(newMin);
  tempDate.setSeconds(0);
  return tempDate;
};

export const isAllDay = (startTime, endTime) => {
  let startOfTheDay =
    startTime.getHours() === 0 && startTime.getMinutes() === 0;
  let endOfTheDay = endTime.getHours() === 23 && endTime.getMinutes() === 59;
  return startOfTheDay && endOfTheDay;
};

//toast

export const toastMsg = (msg, error = false, autoClose = 2000) => {
  if (error) {
    store.addNotification({
      title: "Error",
      message: msg,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: autoClose,
        //   onScreen: true,
        showIcon: true,
      },
    });
  } else {
    store.addNotification({
      title: "Success",
      message: msg,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: autoClose,
        //   onScreen: true,
        showIcon: true,
      },
    });
  }
};

export const toastInfo = (msg, autoClose = 2000) => {
  store.addNotification({
    title: "Info!",
    message: msg,
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: autoClose,
      //   onScreen: true,
      showIcon: true,
    },
  });
};

export const getSlots = (startTime, endTime) => {
  var slots = {};
  const StartTime = startTime.getHours() + startTime.getMinutes() / 60;
  const EndTime = endTime.getHours() + endTime.getMinutes() / 60;
  slots = { StartTime, EndTime };

  return slots;
};

export function useOuterClickNotifier(onOuterClick, innerRef) {
  useEffect(
    () => {
      // only add listener, if the element exists
      if (innerRef.current) {
        document.addEventListener("click", handleClick);
      }

      // unmount previous first in case inputs have changed
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        innerRef.current &&
          !innerRef.current.contains(e.target) &&
          onOuterClick(e);
      }
    },
    [onOuterClick, innerRef] // invoke again, if inputs have changed
  );
}

export function getAssetImgPath(img, user) {
  return typeof img === "string"
    ? img
    : `${AWS_CONSTANTS.USER_IMG_PATH}/${user.uid}/${img.name}`;
}

export async function uploadAssetImg(file, user) {
  if (typeof file === "object") {
    await Storage.put(getAssetImgPath(file, user), file, {
      contentType: file.type,
    });
  }
}

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

// update array with newly edited project
function updateStateObject(inArray, body) {
  const index = inArray.findIndex((e) => e.id === body.id);
  if (index !== -1) {
    const elem = updateObject(inArray[index], body);
    inArray[index] = elem;
  }
  return inArray;
}

export const nextStateObject = (stateObject, body) => {
  // update Arrays
  Object.values(stateObject).forEach((inArray) =>
    updateStateObject(inArray, body)
  );
  return stateObject;
};

//get random accent colors for tiles
export const getRandomAccentColor = (id) => {
  return ACCENT_COLORS[
    Math.abs(id.toString().hashCode() % ACCENT_COLORS.length)
  ];
};

//get edit access of asset
export const getEditAccess = (asset, user) => {
  if (asset.user_uid === user.uid) {
    return true;
  }
  if (asset.userShares && asset.userShares.length > 0) {
    const shared = asset.userShares.find((e) => e.to_entity_uid === user.uid);
    return shared && shared.access_level === ACCESS_LEVEL.EDIT;
  }
  if (asset.orgShares && asset.orgShares.length > 0) {
    const shared = asset.orgShares[0];
    return (
      shared.to_entity_id === user.organization &&
      shared.access_level === ACCESS_LEVEL.EDIT
    );
  }
  return false;
};

// get user's access level for an entity
export const getHighestAccessLevel = (entity, user) => {
  if (entity.user_uid === user.uid) {
    return ACCESS_LEVEL.EDIT;
  }
  let accessLevel = ACCESS_LEVEL.VIEW
  if (entity.shares?.length > 0) {
    const shared = entity.shares.find(
      (e) => e.to_entity_uid === user.uid || e.to_entity_uid === user.organization
    );
    if (shared && shared.access_level === ACCESS_LEVEL.EDIT) {
      return ACCESS_LEVEL.EDIT
    } else if (shared && shared.access_level === ACCESS_LEVEL.EXECUTE) {
      accessLevel = ACCESS_LEVEL.EXECUTE
    }
  }
  return accessLevel;
}


//download S3 object to local downloads
export const downloadBlob = async (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const getPreviousFilePath = (path) => {
  return path.lastIndexOf("/") === -1
    ? ""
    : path.slice(0, path.lastIndexOf("/"));
};

export const getPreviousParentFolder = (filePath, repoContents) => {
  //get file information through repoContents lookup
  let findFile = repoContents.find((file) => file?.path === filePath);
  const components = filePath.split("/");
  components.pop();
  //check if path is pointing at a folder or file
  if (findFile?.type === "file") components.pop();
  return components.join("/");
};

export const getFileNameFromPath = (path) => {
  return path.slice(lastIndexOf(path, "/") + 1);
};

export const getFolderNameFromPath = (path) => {
  let paths = path.split("/");
  return paths[paths.length - 2];
};

export const getFirstFile = (filesList) => {
  for (let file of filesList) {
    if (file.type === "file") {
      return file;
    }
  }
  return null;
};

export const pathIsFileType = (path, filesList) => {
  for (let file of filesList) {
    if (path === file.path) {
      return file.type === "file";
    }
  }
  return false;
};

export const getPreviousFolderFromPath = (path) => {
  // Remove trailing slash if exists
  path = path.replace(/\/$/, "");
  const lastIndex = path.lastIndexOf("/");

  if (lastIndex !== -1) {
    const previousPath = path.substring(0, lastIndex + 1);
    return previousPath;
  } else {
    return "";
  }
};
// [ENG-1907] Make this respect when changes have been made but then removed
export const checkHasUnsavedChanges = (projectFilesContent) => {
  for (const key in projectFilesContent) {
      if (projectFilesContent.hasOwnProperty(key) && projectFilesContent[key].hasUnsavedChanges === true) {
          return true;
      }
  }
  return false;
}

export const getDataSetIdFromS3Path = (path) => {
  let pathSplitArr = path.split("/");
  return pathSplitArr[1];
};

export const isImageFilePath = (filePath) => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
  const lowerCaseFilePath = filePath.toLowerCase();
  return imageExtensions.some((extension) =>
    lowerCaseFilePath.endsWith(extension)
  );
};

export const confirmUnsavedChanges = async () => {
  return swal({
    title: "You have unsaved changes",
    text: "Are you sure you want to leave this page? Unsaved changes will be lost.",
    className: "logout-alert-modal",
    buttons: ["Stay on this page", "Leave the page"],
    showCloseButton: true,
    closeOnClickOutside: true
  });
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const setPermissionTooltip = (desiredAccessLevel, userAccessLevel, action) => {
  const accessLevels = ACCESS_LEVELS
  // filter out desired access level and higher access levels
  const unauthorizedAccessLevels = accessLevels.slice(0, accessLevels.indexOf(desiredAccessLevel))
  const authorizedAccessLevels = accessLevels.slice(accessLevels.indexOf(desiredAccessLevel))
  return unauthorizedAccessLevels.includes(userAccessLevel) ? `You need ${authorizedAccessLevels.join(" or ")} access` : action
};

export const setPermissionOpacity = (desiredAccessLevel, userAccessLevel) => {
  const accessLevels = ACCESS_LEVELS
  // filter out desired access level and higher access levels
  const unauthorizedAccessLevels = accessLevels.slice(0, accessLevels.indexOf(desiredAccessLevel))
  return unauthorizedAccessLevels.includes(userAccessLevel) ? .5 : 1
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const trimSpacesAfterNewlineCharacters = (string) => {
  return string.replaceAll(/\n\s+/g, "\n");
}