import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { EditEntityModal } from "components/shared/Modals/EditEntityModal";
import { DeleteWorkflowModal } from "components/shared/Modals/DeleteWorkflowModal";
import { ShareEntityModal } from "components/shared/Modals/ShareEntityModal/ShareEntityModal";
import { ENTITY_TYPES, PAGE_SIZE_CONSTANTS } from "utility/constants/constants";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StatusCell from "./StatusCell";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import {
  updateWorkflow,
  getWorkflows,
  getWorkflowData,
  createWorkflowShare,
} from "redux/actions/WorkflowActions";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { pauseWorkflow } from "redux/actions/WorkflowActions";
import * as commonService from "utility/CommonService.js";
import { formatTime } from "utility/utility"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { AssignmentOutlined } from "@mui/icons-material";
import { ReplyOutlined } from "@mui/icons-material";
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import { Skeleton } from "@mui/material";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";


const PauseSwitch = styled(Switch)(() => ({
  padding: 4,
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#E5E4E2",
    opacity: "1 !important",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    width: "20px",
    height: "20px",
  },
}));

export const WorkflowRow = ({ index, workflow, user, filterParams, workflowData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [disablePauseSwitch, setDisablePauseSwitch] = useState(false)

  const menuOpen = Boolean(menuAnchor);

  const goToDesigner = () => {
    history.push({
      pathname: `/visual-workflows/${workflow.id}`,
      state: { prevLocation: "Workflows" },
    });
  };

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    if (hours > 0) {
      return `${hours}h, ${minutes}m, ${seconds.toFixed(0)}s`;
    } else if (minutes > 0) {
      return `${minutes}m, ${seconds.toFixed(0)}s`;
    } else {
      return `${seconds.toFixed(2)}s`;
    }
  };

  const handleChange = async (event, workflow) => {
    let id = workflow.id;
    workflow.is_paused = !event.target.checked;
    dispatch(
      pauseWorkflow({
        body: {
          paused: event.target.checked ? false : true,
        },
        id,
      })
    ).then((res) => {
      if (res.value && res.value.success) {
        dispatch(getWorkflows(filterParams.filterParams)).then((res) => {
          dispatch(getWorkflowData({ body: { workflows: res.value.workflows } }))
          setDisablePauseSwitch(false)
        });
        commonService.forSuccess("Workflow update successful!", "Success");
      } else {
        commonService.forError("Workflow update failed", "Error");
      }
    });
  };

  const handleMenuClick = (event, workflow) => {
    setCurrentWorkflow(workflow);
    setMenuAnchor(event.currentTarget);
  };

  const navToProject = (currentWorkflow) => {
    if (currentWorkflow) {
      const path = `projectsv2/${currentWorkflow.project_id}`;
      history.push({ pathname: path, state: { prevLocation: "Workflows" } });
    }
  };

  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  return (
    <>
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell >
            { workflow.visual || workflow.nodes ? (
              <div className="material-symbols-outlined table-icon" title="Designer">
                auto_transmission
              </div>
            ) : (
              <div title="Manual Workflow" className="table-icon">
              <SubtitlesOutlinedIcon  />
              </div>
            )}
        </TableCell>
        <TableCell scope="row">
          {workflowData ? (
            <PauseSwitch
              color="success"
              disabled={disablePauseSwitch}
              checked={!workflowData?.is_paused}
              onChange={(e) => {
                setDisablePauseSwitch(true)
                handleChange(e, workflow);
              }}
              name="workflowRunState"
            />
          ) : (
            <Skeleton size={30}></Skeleton>
          )}
        </TableCell>
        <TableCell scope="row" className="workflow-list-link">
          <Tooltip
            title={
              workflowData?.status === "unpublished" ||
              workflowData?.status === "initializing" ||
              workflowData?.status === "broken"
                ? "Please publish or wait for workflow to be initialized"
                : ""
            }
            placement="top"
          >
            <span>
              <Link
                to={`workflows/${workflow.id}`}
                className={
                  workflowData?.status === "unpublished" ||
                  workflowData?.status === "initializing" ||
                  workflowData?.status === "broken"
                    ? "disabled-link"
                    : ""
                }
              >
                {workflow.name}
              </Link>
            </span>
          </Tooltip>
        </TableCell>
        <StatusCell workflow={workflowData} />
        <TableCell align="left">
          {workflowData ? (
            workflowData?.last_run ? (
              formatTime(workflowData.last_run, user)
            ) : null
          ) : (
            <Skeleton size={30}></Skeleton>
          )}
        </TableCell>
        <TableCell align="left">
          {workflowData ? (
            workflowData?.last_run_duration ? (
              formatDuration(workflowData.last_run_duration)
            ) : null
          ) : (
            <Skeleton size={30}></Skeleton>
          )}
        </TableCell>
        <TableCell align="left"> Owner </TableCell>
        <TableCell align="left">
          <div className="project_info_item" id="info_first_name">
            <div className="info_icns">
              <Tooltip key={workflow.id} title={workflow.user ? workflow.user.first_name : ""}>
                <div className="info_icn_wrapper">
                  {workflow.user && workflow.user.first_name ? (
                    <div>{workflow.user.first_name}</div>
                  ) : (
                    <div>No Name</div> // This is the fallback if there's no user first name
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </TableCell>

        <TableCell>
          <Tooltip title="More" placement="top">
            <IconButton
              className="app_menu mr-2"
              aria-label="more"
              id="long-menu-button"
              aria-controls={menuOpen ? "long-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                handleMenuClick(e, workflow);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Menu
        id="long-menu-button"
        disableScrollLock={true}
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            setShowEditModal(true);
            handleMenuClose();
            e.stopPropagation();
          }}
        >
          <ListItemIcon className="edit">
            <div className="entity_menu_item">
              <img
                src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                alt="Edit Entity"
                className="cursor-pointer optionsIcon"
              />
              <p>Edit</p>
            </div>
          </ListItemIcon>
        </MenuItem>
        {(workflow.visual || workflow.nodes) && ( <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={goToDesigner}
        >
          <ListItemIcon>
            <div className="entity_menu_item">
              <BrushOutlinedIcon></BrushOutlinedIcon>
              <p>Edit in Designer</p>
            </div>
          </ListItemIcon>
        </MenuItem>)}
        <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={() => navToProject(currentWorkflow)}
        >
          <ListItemIcon className="project">
            <div className="workflow_menu_container">
              <AssignmentOutlined />
              <p>Project</p>
            </div>
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          dense={true}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            setShowShareModal(true);
            handleMenuClose();
            e.stopPropagation();
          }}
        >
          <ListItemIcon className="delete">
            <div className="entity_menu_item">
              <ReplyOutlined
                sx={{
                  transform: "scaleX(-1)",
                  width: "25px",
                  height: "25px",
                }}
              />
              <p>Share</p>
            </div>
          </ListItemIcon>
        </MenuItem>
        <DeleteWorkflowModal entity={workflow}
        handleMenuClose={handleMenuClose}
        entityType={ENTITY_TYPES.WORKFLOW}
        >
          <ListItemIcon className="delete">
            <div className="entity_menu_item">
              <DeleteOutline style={{ fontSize: "1.5rem" }} />
              <p>Delete</p>
            </div>
          </ListItemIcon>
        </DeleteWorkflowModal>
      </Menu>
      <EditEntityModal
        entity={workflow}
        entityType={ENTITY_TYPES.WORKFLOW}
        updateEntity={updateWorkflow}
        handleMenuClose={handleMenuClose}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <ShareEntityModal
        entity={workflow}
        entityType={ENTITY_TYPES.WORKFLOW}
        createEntityShare={createWorkflowShare}
        getEntities={getWorkflows}
        handleMenuClose={handleMenuClose}
        entityLimit={PAGE_SIZE_CONSTANTS.WORKFLOWS}
        filterParams={filterParams.filterParams}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />
    </>
  );
};
