import React, { useState, useEffect } from "react";
import InsightTiles from "./InsightTiles";
import ImportInsightModal from "./ImportInsightModal";
import { Button } from "components/shared/ui-components/form-components";
import { Link } from "react-router-dom";
import FilterInsights from "./FilterInsights";
import { DEFAULT_INSIGHT_OPTIONS, ENTITY_TYPES } from "utility/constants/constants";
import { Switch, FormControlLabel } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import ListView from "../../shared/ListView/ListView";
import { useSelector } from "react-redux";
import { formatTime } from "utility/utility"
import { EntityModals } from "../../shared/EntityModals/EntityModals";
import * as actions from "../../../redux/actions/AppActions";

const Insights = () => {
  const [appTypeSort, setAppTypeSort] = useState(DEFAULT_INSIGHT_OPTIONS);
  const [isTableView, setIsTableView] = useState(() => {
    const savedView = localStorage.getItem('insightsTableView');
    return savedView ? JSON.parse(savedView) : false;
  });
  const [rotate, setRotate] = useState(45);
  const [selectedApp, setSelectedApp] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const { insights, insightsPageCount } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);

  const loaderShapeStyle = {
    background: 'rgb(107, 113, 251)',
    height: '4px',
    width: '4px',
    transition: 'transform 1s',
  };

  const loaderLabelStyle = {
    color: 'rgb(107, 113, 251)',
    fontFamily: "'Lato', sans-serif",
    fontSize: 18,
  }

  const loaderStyle = {
    display: 'inline-flex',
    gap: '10px',
    width: '100%',
    height: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  }

  const customLoader = (
    <div style={loaderStyle}>
      <div style={loaderLabelStyle}> Loading </div>
      <div style={{ ...loaderShapeStyle, transform: `rotate(${rotate}deg)` }}></div>
      <div style={{ ...loaderShapeStyle, transform: `rotate(${rotate + 45}deg)` }}></div>
      <div style={{ ...loaderShapeStyle, transform: `rotate(${rotate}deg)` }}></div>
    </div>
  );

  useEffect(() => {
    if (insights.length <= (insightsPageCount - 1) * DEFAULT_INSIGHT_OPTIONS.pageSize) {
      const interval = setInterval(() => setRotate(rotate + 45), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [rotate, insights.length, insightsPageCount]);

  // Update localStorage whenever the toggle state changes
  useEffect(() => {
    localStorage.setItem('insightsTableView', JSON.stringify(isTableView));
  }, [isTableView]);

  const columns = [
    {
      key: 'actions',
      label: '',
      maxWidth: '85px',
      render: (app) => getItemActions(app)
    },
    {
      key: 'name',
      label: 'App Name',
      maxWidth: '150px',
      render: (app) => (
        <Link to={`/app/${app.id}`}>
          {app.name}
        </Link>
      )
    },
    {
      key: 'description',
      label: 'Description',
      maxWidth: '200px'
    },
    {
      key: 'user',
      label: 'Owner',
      maxWidth: '120px',
      render: (app) => app.user ? `${app.user.first_name} ${app.user.last_name}` : "Unknown"
    },
    {
      key: 'created_at',
      label: 'Created',
      maxWidth: '150px',
      render: (app) => formatTime(app.created_at, user) || 0
    },
    {
      key: 'updated_at',
      label: 'Updated',
      maxWidth: '150px',
      render: (app) => formatTime(app.updated_at, user) || 0
    },
    {
      key: 'userMetrics',
      label: 'Views',
      maxWidth: '75px',
      render: (app) => app.userMetrics || 0
    },
  ];

  const insightTilesProps = {
    setAppTypeSort,
    appTypeSort,
    isTableView,
    insights,
    onLoadMore: () => setAppTypeSort({ ...appTypeSort, page: appTypeSort.page + 1 }),
    customLoader,
    onEdit: (app) => {
      setSelectedApp(app);
      setShowEditModal(true);
    },
    onShare: (app) => {
      setSelectedApp(app);
      setShowShareModal(true);
    }
  };

  const { renderTile, getItemActions } = InsightTiles(insightTilesProps);

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="lds_po_heading">
              <h1>Insights</h1>
              <FilterInsights
                setAppTypeSort={setAppTypeSort}
                appTypeSort={appTypeSort}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="lds_il_right_btn">
              <ul style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                <li>
                  <Link
                    to="/add-template"
                    className="cursor-pointer"
                    appTypeSort={appTypeSort}
                  >
                    <Button className="btn">create</Button>
                  </Link>
                </li>
                <li>
                  <ImportInsightModal appTypeSort={appTypeSort} />
                </li>
                <li>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isTableView}
                        onChange={(e) => setIsTableView(e.target.checked)}
                      />
                    }
                    label={
                      <div className="d-flex align-items-center">
                        <ViewModuleIcon className={!isTableView ? "text-primary" : "text-muted"} />
                        <span className="mx-2">/</span>
                        <ViewListIcon className={isTableView ? "text-primary" : "text-muted"} />
                      </div>
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12" style={{ paddingBottom: '30px' }}>
            <ListView
              items={insights}
              totalPages={insightsPageCount}
              pageSize={DEFAULT_INSIGHT_OPTIONS.pageSize}
              onLoadMore={() => setAppTypeSort({ ...appTypeSort, page: appTypeSort.page + 1 })}
              renderTile={renderTile}
              columns={columns}
              emptyScreenEntity={ENTITY_TYPES.INSIGHT}
              getItemActions={getItemActions}
              customLoader={customLoader}
              isTableView={isTableView}
              className="lds_po_tabs_main_box"
              user={user}
            />
          </div>
        </div>
        {/* Single instance of EntityModals */}
        {selectedApp && (
          <EntityModals
            entity={selectedApp}
            entityType={ENTITY_TYPES.APPLICATION}
            updateEntity={actions.updateApp}
            createEntityShare={actions.createAppShare}
            getEntities={actions.getInsights}
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
          />
        )}
      </div>
    </div>
  );
};

export default Insights;