import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReplyOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

export const EntityMenu = ({
  onEdit,
  onShare,
  onDelete,
  editLabel = 'Edit',
  shareLabel = 'Share',
  deleteLabel = 'Delete',
  tooltipTitle = 'More'
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAction = (action, e) => {
    e.stopPropagation();
    handleMenuClose();
    action();
  };

  return (
    <>
      <Tooltip title={tooltipTitle} placement="top">
        <IconButton
          className="app_menu mr-2"
          aria-label="more"
          id="long-menu-button"
          aria-controls={menuOpen ? 'long-menu' : undefined}
          aria-expanded={menuOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="long-menu-button"
        disableScrollLock={true}
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {onEdit && (
          <MenuItem
            dense={true}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => handleAction(onEdit, e)}
          >
            <ListItemIcon className="edit">
              <div className="entity_menu_item">
                <img
                  src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                  alt="Edit"
                  className="cursor-pointer optionsIcon"
                />
                <p>{editLabel}</p>
              </div>
            </ListItemIcon>
          </MenuItem>
        )}
        {onShare && (
          <MenuItem
            dense={true}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => handleAction(onShare, e)}
          >
            <ListItemIcon className="share">
              <div className="entity_menu_item">
                <ReplyOutlined
                  sx={{
                    transform: "scaleX(-1)",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <p>{shareLabel}</p>
              </div>
            </ListItemIcon>
          </MenuItem>
        )}
        {onDelete && (
          <MenuItem
            onClick={(e) => handleAction(onDelete, e)}
          >
            <ListItemIcon className="delete">
              <DeleteIcon fontSize="small" />
              <p>{deleteLabel}</p>
            </ListItemIcon>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};