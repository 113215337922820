import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyScreen from "../ListPage/emptyScreen";
import "./ListView.scss";

const ListView = ({
  items,
  totalPages,
  pageSize,
  onLoadMore,
  renderTile,
  columns,
  emptyScreenEntity,
  onItemClick,
  customLoader,
  className,
  isTableView,
  getItemActions
}) => {
  const renderTableView = () => (
    <div className="ludis_table_container">
      <table className="ludis_table">
        <thead>
          <tr className="ludis_table_row">
            {columns.map((column) => (
              <th
                key={column.key}
                className="ludis_table_header_cell"
                title={column.label}
                style={{
                  maxWidth: column.maxWidth,
                  width: column.maxWidth
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr
              key={item.id}
              className="ludis_table_row"
              onClick={() => onItemClick && onItemClick(item)}
              style={onItemClick ? { cursor: 'pointer' } : {}}
            >
              {columns.map((column) => {
                const cellContent = column.render ? column.render(item) : item[column.key];
                return (
                  <td
                    key={column.key}
                    className="ludis_table_cell"
                    title={cellContent}
                    style={{
                      maxWidth: column.maxWidth,
                      width: column.maxWidth
                    }}
                  >
                    {cellContent}
                  </td>
                );
              })}
              { getItemActions }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderContent = () => {
    if (items.length === 0) {
      return <EmptyScreen entity={emptyScreenEntity} />;
    }

    return isTableView ? renderTableView() : (
      <div className="row">
        {items.map((item) => renderTile(item))}
      </div>
    );
  };

  return (
    <div className={`list-view-container ${className || ''}`}>
      <InfiniteScroll
        dataLength={items.length}
        next={onLoadMore}
        hasMore={items.length % pageSize === 0 && items.length / pageSize < totalPages}
        loader={customLoader}
        endMessage={
          items.length > 0 && (
            <p style={{ textAlign: 'center' }}>
              <b>No more items to load.</b>
            </p>
          )
        }
      >
        <div className={isTableView ? "" : "row"}>
          <div className="tab-content float_left">
            <div className="col-md-12">
              {renderContent()}
            </div>
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ListView;