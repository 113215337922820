import React from 'react';
import { ShareEntityModal } from "../Modals/ShareEntityModal/ShareEntityModal";
import { EditEntityModal } from "../Modals/EditEntityModal";

export const EntityModals = ({
  entity,
  entityType,
  updateEntity,
  createEntityShare,
  getEntities,
  showEditModal,
  setShowEditModal,
  showShareModal,
  setShowShareModal
}) => {
  return (
    <>
      <EditEntityModal
        entity={entity}
        entityType={entityType}
        updateEntity={updateEntity}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <ShareEntityModal
        entity={entity}
        entityType={entityType}
        createEntityShare={createEntityShare}
        getEntities={getEntities}
        showModal={showShareModal}
        setShowModal={setShowShareModal}
      />
    </>
  );
};
