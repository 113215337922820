import axios from "../config";
import { API_VERSION } from "config";
//User API

export const getUsers = (params) =>
  axios.get(`${API_VERSION}/users`, { params });

export const getUser = (id) =>
  axios.get(`${API_VERSION}/users/${id}`);

export const createUser = (body) =>
  axios.post(`${API_VERSION}/users`, body);

export const deleteUser = (id) =>
  axios.delete(`${API_VERSION}/users/${id}`);

export const updateUser = (body, id) =>
  axios.put(`${API_VERSION}/users/${id}`, body);

export const migrateEntityOwner = (body) =>
  axios.post(`${API_VERSION}/auth/migrateEntityOwner`, body);
