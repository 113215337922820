import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as actions from "../../../redux/actions/AppActions";
import * as commonService from "utility/CommonService.js";
import { AppTile } from "./AppTile";
import { getHighestAccessLevel } from "utility/utility";
import { ACCESS_LEVEL } from "utility/constants/constants";
import Tooltip from "@mui/material/Tooltip";
import StarIcon from "@mui/icons-material/Star";
import { EntityMenu } from "../../shared/EntityMenu/EntityMenu";

const InsightTiles = ({
  appTypeSort,
  onEdit,
  onShare
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authReducer);
  const { appShares, pinnedApplications } = useSelector(
    (state) => state.appReducer
  );

  const isPinned = (app) => {
    if (pinnedApplications && pinnedApplications.find((pin) => pin.id === app.id))
      return true;
    else return false;
  };

  const handlePin = (app) => {
    dispatch(
      actions.createAppPin({
        body: {
          app_pin: {
            app_id: app.id,
            user_id: user.id,
            pinned: !isPinned(app),
          },
        },
      })
    ).then((res) => {
      dispatch(
        actions.updatePinnedApps({
          body: {
            app: app,
            pinned: res.value.applicationPinInfo.pinned,
          },
        })
      );
    });
  };

  const deleteApp = (app) => {
    let id = app.id;
    if (isPinned(app)) {
      handlePin(app);
    }
    swal({
      text: "Are you sure you want remove this from the insights page?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(actions.deleteApp({ id })).then((res) => {
          if (res.value && res.value.success) {
            commonService.forSuccess("App Deleted!", "Success");
          }
        });
      }
    });
  };

  const deleteAppShare = (app) => {
    let id = app.id;
    if (isPinned(app)) {
      handlePin(app);
    }
    swal({
      text: "Are you sure you want remove this from the insights page?",
      className: "logout-alert-modal",
      buttons: [true, "Delete"],
    }).then((result) => {
      if (result) {
        dispatch(actions.deleteAppShare(id)).then(
          (res) => {
            if (res.value && res.value.success) {
              commonService.forSuccess("App Deleted!", "Success");
            }
          },
          [dispatch]
        );
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getAppPins({ params: null }));
    dispatch(actions.getInsights({ appTypeSort }));
  }, [dispatch, appTypeSort]);

  const getItemActions = (app) => (
    <>
      <div className="show__tool">
          <Tooltip title={isPinned(app) ? "Unpin" : "Pin"} placement="top">
            <StarIcon
              className={isPinned(app) ? "show_pin_app" : "hide_pin_app"}
              onClick={(e) => handlePin(app)}
            />
          </Tooltip>
        </div>
      <EntityMenu
        onEdit={getHighestAccessLevel(app, user) === ACCESS_LEVEL.EDIT
          ? () => onEdit(app)
          : null
        }
        onShare={getHighestAccessLevel(app, user) === ACCESS_LEVEL.EDIT
          ? () => onShare(app)
          : null
        }
        onDelete={() => appShares.includes(app) ? deleteAppShare(app) : deleteApp(app)}
        editLabel="Edit"
        shareLabel="Share"
        deleteLabel="Delete"
        tooltipTitle="More"
      />
    </>
  );

  const renderTile = (app) => (
    <AppTile
      key={app.id}
      app={app}
      handlePin={handlePin}
      isPinned={isPinned(app)}
      deleteApp={appShares.includes(app) ? deleteAppShare : deleteApp}
      appsPage={appTypeSort.page}
      accessLevel={getHighestAccessLevel(app, user)}
    />
  );

  return { renderTile, getItemActions };
};

export default InsightTiles;